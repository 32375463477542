import extend from 'lodash/extend';
import ApplicationController from 'cloudstore/controllers/application';
import ButtonComponent from 'components/button';
import AccordionComponent from 'components/accordion';

class CloudstoreProductsSearchController extends ApplicationController {
  constructor(options = {}) {
    super(options);

    extend(this.components, {
      button: new ButtonComponent(),
      accordion: new AccordionComponent(),
    });
  }

  init() {
    super.init();

    let {
      button,
      accordion,
    } = this.components;

    button.init({
      selector: '.search-input__submit',
      actions: {
        onClick: button.setLoadingState.bind(button),
      }
    });
    accordion.init({ scrollToOnExpand: false });
  }
}

export default CloudstoreProductsSearchController;
