import CloudstoreStoresShowController from 'cloudstore/controllers/cloudstore_stores_show';
import CloudstoreProductsIndexController from 'cloudstore/controllers/cloudstore_products_index';
import CloudstoreProductsShowController from 'cloudstore/controllers/cloudstore_products_show';
import CloudstoreProductsSearchController from 'cloudstore/controllers/cloudstore_products_search';
import ApplicationController from 'cloudstore/controllers/application';

let Controllers = {
  CloudstoreStoresShowController,
  CloudstoreProductsIndexController,
  CloudstoreProductsShowController,
  CloudstoreProductsSearchController,
};

export function getClassByName(controllerName) {
  return Controllers[controllerName] || ApplicationController;
}
