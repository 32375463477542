import $ from 'jquery';
import merge from 'lodash/merge';
import bind from 'lodash/bind';

class NavigationListComponent {
  constructor() {}

  static get defaults() {
    return Object.freeze({
      selector: '.navigation-list',
      classes: {
        select: 'navigation-list__select'
      },
      actions: {}
    });
  }

  _transitionToPath(event) {
    let href = $(event.currentTarget).val();

    window.location.href = href;
  }

  _setDomDependentOptions() {
    this.options.$scope = $(this.options.selector);
    this.options.$select = this.options.$scope.find(`.${this.options.classes.select}`);
  }

  _attach() {
    this.options.$scope.on(
      'change.navigation-list:change',
      `.${this.options.classes.select}`,
      bind(this._transitionToPath, this)
    );
  }

  init(options = {}) {
    this.options = merge({}, this.constructor.defaults, options);

    this._setDomDependentOptions();
    this._attach();
  }
}

export default NavigationListComponent;
