import extend from 'lodash/extend';
import ApplicationController from 'cloudstore/controllers/application';
import NavigationListComponent from 'components/navigation_list';
import CloudstoreSearchInputComponent from 'components/cloudstore_search_input';
import ButtonComponent from 'components/button';

class CloudstoreProductsIndexController extends ApplicationController {
  constructor(options = {}) {
    super(options);

    extend(this.components, {
      navigationList: new NavigationListComponent(),
      mobileCloudstoreSearchInput: new CloudstoreSearchInputComponent(),
      button: new ButtonComponent(),
    });
  }

  init() {
    super.init({
      cloudstoreSearchInput: {
        selector: '.cloudstore-category__search-input--desktop'
      }
    });

    let {
      navigationList,
      mobileCloudstoreSearchInput,
      button,
    } = this.components;

    navigationList.init();
    mobileCloudstoreSearchInput.init({ selector: '.cloudstore-category__search-input--mobile' });
    button.init({
      selector: '.search-input__submit',
      actions: {
        onClick: button.setLoadingState.bind(button),
      }
    });
  }
}

export default CloudstoreProductsIndexController;
