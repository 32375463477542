import extend from 'lodash/extend';
import ApplicationController from 'cloudstore/controllers/application';
import ButtonComponent from 'components/button';

class CloudstoreStoresShowController extends ApplicationController {
  constructor(options = {}) {
    super(options);

    extend(this.components, {
      button: new ButtonComponent(),
    });
  }

  init() {
    super.init();

    let {
      button,
    } = this.components;

    button.init({
      selector: '.search-input__submit',
      actions: {
        onClick: button.setLoadingState.bind(button),
      }
    });
  }
}

export default CloudstoreStoresShowController;
