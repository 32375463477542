import extend from 'lodash/extend';
import ApplicationController from 'cloudstore/controllers/application';
import CalendarComponent from 'components/calendar';
import PhotosComponent from 'components/photos';
import ImageGalleryComponent from 'components/image_gallery';
import ButtonComponent from 'components/button';

class CloudstoreProductsShowController extends ApplicationController {
  constructor(options = {}) {
    super(options);

    extend(this.components, {
      photos: new PhotosComponent(),
      imageGallery: new ImageGalleryComponent(),
      calendar: new CalendarComponent(),
      button: new ButtonComponent(),
    });
  }

  init() {
    super.init();

    let {
      photos,
      imageGallery,
      calendar,
      button,
    } = this.components;

    photos.init();
    imageGallery.init({ selector: '.photos__carousel' });
    calendar.init();
    button.init({
      selector: '.search-input__submit',
      actions: {
        onClick: button.setLoadingState.bind(button),
      }
    });
  }
}

export default CloudstoreProductsShowController;
