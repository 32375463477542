import extend from 'lodash/extend';
import Logger from 'utils/logger';
import CloudstoreTopBarComponent from 'components/cloudstore_top_bar';
import LazyLoaderComponent from 'components/lazy_loader';
import AuthFlowComponent from 'components/auth_flow';
import CloudstoreSearchInputComponent from 'components/cloudstore_search_input';
import LanguageSelectorComponent from 'components/language_selector';
import CookieNotificationComponent from 'components/cookie_notification';

class ApplicationController {
  constructor(options = {}) {
    extend(this, options);

    this.components = {
      cloudstoreTopBar: new CloudstoreTopBarComponent(),
      lazyLoader: new LazyLoaderComponent(),
      authFlow: new AuthFlowComponent(),
      cloudstoreSearchInput: new CloudstoreSearchInputComponent(),
      languageSelector: new LanguageSelectorComponent(),
      cookieNotification: new CookieNotificationComponent(),
    };
  }

  init(options={}) {
    let {
      cloudstoreTopBar,
      lazyLoader,
      authFlow,
      cloudstoreSearchInput,
      languageSelector,
      cookieNotification
    } = this.components;

    cloudstoreTopBar.init(options.cloudstoreTopBar);
    lazyLoader.init(options.lazyLoader);
    authFlow.init(options.authFlow);
    cloudstoreSearchInput.init(options.cloudstoreSearchInput);
    languageSelector.init();
    cookieNotification.init(options.cookieNotification);

    Logger.log('DEBUG:', this);
  }
}

export default ApplicationController;
